/*** Reset ***/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr,	acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, 
q, s, samp, small, strike, strong,	sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, 
tr, th, td, article, aside, canvas,	details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 62.5%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    line-height: 1;
    -webkit-text-size-adjust: none;
    -ms-overflow-style: scrollbar;
    font-family: 'Main-Font', 'Arial', 'sans-serif';
    background: var(--bg-color);
}

@media screen and (max-width: 480px) {
    html, body {
        min-width: 320px;
    }
}

@-ms-viewport {
    width: device-width;
}
