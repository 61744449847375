#root {
    max-width: 500px;
    margin: auto;
    /* padding-top: 0.5rem; */
}

nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--shade-color);
    padding-top: 0.5rem;
    border-bottom: 1px solid var(--heading-color);
}

nav ul {
    width: 90%;
}
nav li {
    list-style: none;
    text-align: center;
    padding: 0.8rem;
    margin-bottom: 0.5rem;
    width: 25%;
    float: left;
}

.section {
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-family: "Bold-Font";
    color: var(--font-color);
    padding: 1rem;
    border: 1px solid var(--highlight-color);
    background: var(--heading-color);
    box-shadow: 5px 10px 8px var(--image-shadow-color);
}

.formRow {
    width: 100%;
    display: block;
    clear: both;
    margin: 1rem;
    text-align: left;
}

.weightForm {
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    padding: 1rem;
}

.weightForm label {
    margin-right: 1rem;
}

.activityForm {
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    padding: 1rem;
}

.activityForm label {
    display: block;
    float: left;
    margin-right: 1rem;
    width: 100px;
    line-height: 1.5rem;
}

.chart {
    width: 100%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.chart-row {
    text-align: center;
    width: 90%;
    margin: 0 auto;
    padding-top: 0.5rem;
}

.homeIcons {
    width: 90%;
    text-align: center;
    padding-top: 2rem;
}

.homeIcons p {
    margin: 1rem;
    width: 100%;
    margin-bottom: 2rem;
}

.homeIcons ul {
    list-style: none;
}

.homeIcons li a {
    display: block;
    color: var(--link-color);
    border: 2px solid var(--link-color);
    width: 50%;
    margin-left: 25%;
    padding: 1rem;
    margin-bottom: 1rem;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Main-Font';
    font-size: 1.2rem;
    font-weight: bold;
}

.homeIcons li a:hover, .homeIcons li a:focus {
    color: var(--font-color);
    border-color: var(--font-color);
}

.homeIcons button {
    background: transparent;
    border: none;
    color: var(--link-color);
    text-decoration: none;
    cursor: pointer;
    font-family: 'Main-Font';
    font-size: 1rem;
    font-weight: bold;
}

.message {
    color: var(--highlight-color);
    border: 1px solid var(--highlight-color);
    background: var(--shade-color);
    padding: 1rem;
    margin: auto;
    margin-top: 2rem;
    width: 80%;
    text-align: center;
}

.submitted {
    color: var(--ok-color);
    border: 1px solid var(--ok-color);
    background: var(--shade-color);
    padding: 1rem;
    margin: auto;
    width: 80%;
    text-align: center;
}
.submitted p {
    color: var(--ok-color);
    padding: 0.2rem;
    margin: 0;
}

.warning {
    color: var(--warning-color);
    border: 1px solid var(--warning-color);
    background: var(--shade-color);
    padding: 1rem;
    margin: auto;
    margin-top: 2rem;
    width: 80%;
    text-align: center;
}