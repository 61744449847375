
/*** Fonts ***/

@font-face {
    font-family: 'Main-Font';
    font-weight: 100;
    src: url("../fonts/Muli-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Bold-Font';
    font-weight: 500;
    src: url("../fonts/Muli-Bold.ttf") format("truetype");
}

/*** Base HTML elements ***/

p {
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    color: var(--font-color);
}

li {
    line-height: 1.5rem;
    color: var(--font-color);
}

a {
    color: var(--link-color);
    text-decoration: none;
    cursor: pointer;
}

a:hover, a:focus, a:active {
    color: var(--link-color);
}

hr {
    border: 0;
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 90%;
    border-bottom: 1px solid var(--highlight-color);
}

h1, h2, h3, h4, h5 {
    font-family: 'Bold-Font', 'Arial', 'sans-serif';
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    color: var(--font-color);
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

em {
    font-style: italic;
}

strong {
    font-family: 'Bold-Font', 'Arial', 'sans-serif';
}
