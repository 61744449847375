:root {

    --bg-color: #262829;
    --font-color: #C5C8C6;
    --shade-color: #0f1114;
    --heading-color: #000000;
    --image-shadow-color: rgb(136, 132, 216, 0.4);
    --highlight-color: #8884d8;
    --warning-color: #8d1f40;
    --ok-color: #82ca9d;

    --button-bg-color: var(--shade-color);
    --button-hover-color: var(--highlight-color);
    --button-hover-bg-color: var(--bg-color);
    --button-disabled-color: var(--bg-color);

    --link-color: var(--highlight-color);

}


@import "reset.css";
@import "base.css";
@import "forms.css";
@import "elements.css";
