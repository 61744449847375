
/*** Forms ***/

.datePicker {
    width: auto;
}

label {
    color: var(--font-color);
}

input[type=text] {
    border: 0;
    border-bottom: 1px solid var(--font-color);
    width: 10rem;
    color: var(--font-color);
    background-color: transparent;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    padding: 0 2rem;
    padding: 0 0 0 0.5rem;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    transition: all 0.30s ease-in-out;
    outline: none;
    font-size: 0.9rem;
}

input[type=text]:focus {
    border-bottom: 1px solid var(--highlight-color) !important;
}

select {
    border: 1px solid var(--font-color)  !important;
    color: var(--font-color);
    background-color: var(--button-bg-color);
    letter-spacing: 0.1rem;
    height: 2rem;
    width: 10rem;
    padding: 0 2rem;
    padding: 0 0 0 0.5rem;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    transition: all 0.30s ease-in-out;
    outline: none;
    font-size: 0.9rem;
}

input[type="submit"] {
    border: 2px solid var(--font-color)  !important;
    color: var(--font-color);
    background-color: var(--button-bg-color);
    line-height: 2rem;
    margin-left: 1rem;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9rem;
    font-family: "Bold-Font";
    letter-spacing: 0.1rem;
    padding: 0 2rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    -moz-transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease;
    -webkit-transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease;
    -ms-transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease;
    transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease;

}

input[type="submit"]:hover, input[type="submit"]:focus, input[type="submit"]:active {
    background-color: var(--button-hover-bg-color);
    border: 2px solid var(--button-hover-color)  !important;
    color: var(--button-hover-color) !important;
}

input[type="submit"].disabled, input[type="submit"]:disabled {
    color: var(--button-disabled-color) !important;
    border-color: var(--button-disabled-color) !important;
    -moz-pointer-events: none;
    -webkit-pointer-events: none;
    -ms-pointer-events: none;
    pointer-events: none;
}
input[type="submit"].disabled:before, input[type="submit"]:disabled:before,
input[type="submit"].disabled:after, input[type="submit"]:disabled:after {
    color: var(--button-disabled-color) !important;
}
